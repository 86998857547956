import React, { createRef } from "react";
import clsx from "clsx";

import chaveMovelIcon from '../../assets/img/icones_cmd.png';
import { RadioGroup } from '../../components/input/RadioGroup';
import SimpleButtonComponent from "../../components/button/simple_button";
import { LinkButton } from "../../components/button/LinkButton";
import LoadingComponent from "../../components/loading/load_panel";
import { showError } from "../../utils/notification/showFeedback";
import resourcesConfig from "../../config/api_resources.json";
import * as auth from "../../utils/authentication/authentication";
import { createCaptcha, validateCaptcha } from "../../utils/captcha/captcha_helper";
import { caller } from "../../utils/integration/integration";
import { Icon } from "../../components/Icon/Icon";
import { InputLabel } from "../../components/input/InputLabel";
import { InputNumber } from "../../components/input/InputNumber";
import { InputText } from "../../components/input/InputText";
import { localStorageHelper } from "../../utils/storage/local_storage";
import { getLabel, getLink } from "../../utils/translates/login_labels";
import "./login_modal.css";
import { InvalidFieldListFeedback } from "../../components/Alert/InvalidFieldListFeedback";
import { SecurityInformation } from "../../components/SecurityInformation/SecurityInformation";
import { BackButton } from "../../components/button/BackButton";
import { LOCAL_STORAGE } from "../../utils/storage/constants";

const USERNAME_PREFIX = 'WEB_';

class LoginModal extends React.Component {
    constructor(props) {
        super(props);

        this.refErrorShowed = createRef();
        this.refErrorShowed.current = false;

        this.state = {
            isAuthenticated: false,
            userName: '',
            isLoadedChaveMovelDigital: false,
            SAMLRequest: null,
            RelayState: null,
            action: null,
            isLoading: false,
            recoverySuccess: false,
            recoveryFailed: false,
            isForgotSelected: false,
            forgotSelectedTab: 0,
            errorShowed: false,
            isError: false,
            errorMessage: "",
            login: {
                username: '',
                password: ''
            }
        }
    }

    switchForgotCredentialsTab = (event) => {
        this.setState(state => ({
            ...state,
            forgotSelectedTab: Number(event.target.value)
        }), function(){
            this.clearInputs();
            createCaptcha("forgotCaptcha");
        });
    }

    clearInputs = () => {
        var inputElements = document.getElementsByTagName('input');
        var keyElements = [ "security", "email", "username", "nif" ];

        for (var i=0; i < inputElements.length; i++) {
            if (inputElements[i].type == 'text' && keyElements.includes(inputElements[i].name)) {
                inputElements[i].value = '';
                inputElements[i].classList.remove("invalid-fields");
            }
        }
    }

    setForgotCredentials = () => {
        this.setState(state => ({
            ...state,
            isForgotSelected: !this.state.isForgotSelected,
            recoveryFailed: false,
            recoverySuccess: false
        }), function(){
            this.clearInputs();
            this.state.isForgotSelected && createCaptcha("forgotCaptcha");
        });
    }

    removeValidationFields = (e) => {
        e.target.classList.remove("invalid-fields");
    }

    validateRecoveryFields = () => {
        let allValid = true;
        if(!validateCaptcha(document.getElementById("security").value)){
            document.getElementById("security").classList.add("invalid-fields");
            allValid = false;
        }

        if(this.state.forgotSelectedTab === 1){
            let nif = document.getElementById("nif").value;
            if(nif.length !== 9){
                document.getElementById("nif").classList.add("invalid-fields");
                allValid = false;
            }
        }else{
            let username = document.getElementById("username").value;
            if(username.length < 3){
                document.getElementById("username").classList.add("invalid-fields");
                allValid = false;
            }
        }

        let validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let email = document.getElementById("email").value;
        if (!email.match(validEmailRegex)) {
            document.getElementById("email").classList.add("invalid-fields");
            allValid = false;
        }
        
        return allValid;
    }

    sendNewCredentials = () => {
        if(!this.validateRecoveryFields())
            return;

        if(this.state.forgotSelectedTab === 1){
            const nif = document.getElementById("nif").value;
            const email = document.getElementById("email").value;
            this.recoveryUser(nif, email);
        }else{
            const username = document.getElementById("username").value;
            const email = document.getElementById("email").value;
            this.recoveryPassword(username, email);
        }
    }

    setError = (errorMessage) => {
        var isError = false;
        if (typeof errorMessage !== 'string')
            errorMessage = getLabel("ERROR");

        if (errorMessage.includes("000"))
            errorMessage = getLabel("UNEXPECTED_ERROR");

        if(errorMessage.includes("002"))
            errorMessage = getLabel("INVALID_CREDENTIALS");

        if(errorMessage.includes("004"))
            errorMessage = getLabel("INVALID_NIF");

        const isAuthenticated = false;

        if (errorMessage) {
            errorMessage = errorMessage.replace(/^\[.*\] - /, '');
            isError = true;
        }

        this.setState(state =>({
            ...state,
            isAuthenticated,
            userName: '',
            isLoading: false,
            errorMessage: errorMessage,
            isError: isError
        }));
    }
    
    setSuccess = (auth) => {
        const isAuthenticated = true;

        this.setState(state => ({
            ...state,
            isAuthenticated,
            userName: auth.userName,
            isLoading: false
        }), function() {
            if (this.props.handleAuthentication) {
                this.props.handleAuthentication(isAuthenticated, auth);
            }
        });
    }

    recoveryPassword = (username, email) => {
        const userName = username.startsWith(USERNAME_PREFIX) ? username : USERNAME_PREFIX.concat(username);

        caller.postWithoutAuth(resourcesConfig.AUTHENTICATION.RECOVERY_PASSWORD, {
            Type: "PWDREQ",
            Parameters: `{'UserName': '${userName}', 'Email': '${email}'}`,
            Details: null,
            Reference: username
        }).then(response => {
            this.setState(state => ({
                ...state,
                recoverySuccess: !response.isError,
                recoveryFailed: response.isError
            }));
        }).catch(err => {
            this.setState(state => ({
                ...state,
                recoveryFailed: true,
                recoverySuccess: false
            }));
        });
    }

    recoveryUser = (nif, email) => {
        caller.postWithoutAuth(resourcesConfig.AUTHENTICATION.RECOVERY_USER, {
            Type: "USRREQ",
            Parameters: `{'NIF': ${nif}, 'Email': '${email}'}`,
            Details: null,
            Reference: nif
        }).then(response => {
            this.setState(state => ({
                ...state,
                recoverySuccess: !response.isError,
                recoveryFailed: response.isError
            }));
        }).catch(err => {
            this.setState(state => ({
                ...state,
                recoveryFailed: true,
                recoverySuccess: false
            }));
        });
    }

    authenticate = (loginType) => {
        switch(loginType){
            case 1:
                auth.authenticate({
                    login: this.props.loginByAuthDialog ? this.props.userName : this.state.login.username, 
                    password: this.props.loginByAuthDialog ? this.props.userName : this.state.login.username, 
                    isChaveMovelDigital: false, 
                    accessType: loginType,
                    metadata: this.props.loginByAuthDialog ? this.props.userName : this.state.login.username
                })
                .then(authResult => {
                    this.setSuccess(authResult);
                }).catch(err => {
                    this.setError(err);
                });
                break;
            default:
                auth.authenticate({
                    login: this.props.loginByAuthDialog ? this.props.userName : this.state.login.username, 
                    password: this.state.login.password, 
                    isChaveMovelDigital: false, 
                    accessType: loginType
                })
                .then(authResult => {
                    this.setSuccess(authResult);
                }).catch(err => {
                    this.setError(err);
                });
                break;
        }
    }

    handleSubmit = (loginType) => {
        if(!this.props.loginByAuthDialog) 
            localStorageHelper.clearLocal();
        
        this.setState(state => ({
            ...state,
            isLoading: true
        }), this.authenticate(loginType));
    };

    getRedirectUrl = () => {
        if(window.location.pathname.includes("/V5/")){
            return `${window.location.origin}/V5`;
        } else {
            return window.location.origin;
        }
    }

    submitChaveMovelDigital = () => {
        localStorageHelper.clearLocal();

        caller.getWithoutAuth(resourcesConfig.AUTHENTICATION.CHAVEMOVELDIGITALREQUEST, {redirectUrl: this.getRedirectUrl()})
        .then(response => {
            var form = document.createElement("form");
            var SAMLRequest = document.createElement("input");
            var RelayState = document.createElement("input");
            
            form.method = "POST";
            form.action = response.result.action; 
            
            SAMLRequest.value = response.result.samlRequest;
            SAMLRequest.name = "SAMLRequest";
            form.appendChild(SAMLRequest);  

            RelayState.value = response.result.relayState;
            RelayState.name = "RelayState";
            form.appendChild(RelayState);

            document.body.appendChild(form);

            form.submit();
        }).catch(err => {
            this.setError(err);
        });
    };
    
    renderLoggedOutMessage() {
        let params = new URLSearchParams(window.location.search);
        let unauthorized = params.get('u');

        return unauthorized === '3' && !this.state.isError && (
            <p className="flex items-center px-12 gap-3 text-brand-green600 h-10">
                <Icon name='validar' />
                <span className="font-bold">{getLabel('SUCCESS_LOGOUT_FEEDBACK')}</span>
            </p>
        );
    }

    renderFinishSessionMessage = () => {
        if(this.state.isError){
            return (<InvalidFieldListFeedback messages={[this.state.errorMessage]} translatable={false}/>);
        }
        if(!navigator.cookieEnabled){
            return (<InvalidFieldListFeedback messages={[getLabel("COOKIES_DISABLED")]} translatable={false}/>);
        }
        let params = new URLSearchParams(window.location.search);
        let unauthorized = params.get('u');
        if(unauthorized === '1' && !localStorageHelper.getValue(LOCAL_STORAGE.EXPIRED_USER_MESSAGE_VIEW)){
            localStorageHelper.setValue(LOCAL_STORAGE.EXPIRED_USER_MESSAGE_VIEW, true);
            return (<InvalidFieldListFeedback messages={[getLabel("UNAUTHORIZED")]} translatable={false}/>);
        }
        if(unauthorized === '2' && !this.refErrorShowed.current){
            this.refErrorShowed.current = true;
            return (<InvalidFieldListFeedback messages={[getLabel("ERROR")]} translatable={false}/>);
        }
        if(unauthorized === '4' && !this.refErrorShowed.current){
            this.refErrorShowed.current = true;
            return (<InvalidFieldListFeedback messages={[getLabel("INCORRECT_PIN")]} translatable={false}/>);
        }
    }

    renderForgotCredentialsForm = () => {
        return (
            <div className='flex flex-col gap-4'>
                <div className='flex justify-center'>
                    <RadioGroup
                        id='login-recovery-type'
                        layout='horizontal'
                        onChange={this.switchForgotCredentialsTab}
                        options={[
                            { label: getLabel('PASSWORD'), value: 0 },
                            { label: getLabel('USER'), value: 1 },
                        ]}
                        selectedOption={this.state.forgotSelectedTab}
                    />
                </div>

                {this.state.forgotSelectedTab === 0 ? (
                    <InputText
                        key='username-recovery'
                        id='username'
                        name='username'
                        label={getLabel("USER")}
                        onKeyDown={this.removeValidationFields}
                    />
                ) : (
                    <InputNumber
                        id='nif'
                        name='nif'
                        onKeyDown={this.removeValidationFields}
                        label={getLabel("NIF")}
                        maxLength='9'
                        onKeyPress={(e) => {
                            if (e.target.value.length >= 8) 
                                e.target.value = e.target.value.slice(0, 8);
                        }}
                    />
                )}

                <InputText
                    key='email'
                    id='email'
                    name='email'
                    label={getLabel("EMAIL")}
                    onKeyDown={this.removeValidationFields}
                />

                <fieldset className='flex flex-col gap-2 group'>
                    <InputLabel inputId='security'>{getLabel("SECURITY_CODE")}</InputLabel>

                    <div className='flex gap-x-2'>
                        <div className="flex-1">
                            <InputText
                                autoComplete='off'
                                id='security'
                                name='security'
                                onKeyDown={this.removeValidationFields}
                            />
                        </div>

                        <div className="flex gap-x-2 flex-1">
                            <div
                                id='forgotCaptcha'
                                name='forgotCaptcha'
                                className="w-full content-center"
                            />

                            <SimpleButtonComponent
                                title={getLabel("NEW_CODE")}
                                variant='icon-button'
                                onClick={() => createCaptcha("forgotCaptcha")}
                            >
                                <Icon name='atualizar' />
                            </SimpleButtonComponent>
                        </div>
                    </div>
                </fieldset>

                <div className="flex gap-4 lg:gap-2 flex-wrap sm:flex-nowrap">
                    <SimpleButtonComponent
                        className='w-full sm:w-1/2 font-semibold'
                        onClick={this.sendNewCredentials}
                    >
                        <span>{getLabel("SEND")}</span>
                    </SimpleButtonComponent>
                    
                    <div className="flex w-full sm:w-1/2 justify-center">
                        {this.renderGoBackButton()}
                    </div>
                </div>
            </div>
        );
    };

    handleChangeLoginInput(newValue) {
        this.setState(state => ({
            ...state,
            login: { ...state.login, ...newValue }
        }));
    }

    renderLoginFormNIF() {
        return (
            <>
                <form onSubmit={() => this.handleSubmit(1)} className="grid grid-cols-1 justify-between gap-4">
                    <InputNumber
                        key='taxNumber'
                        name="taxNumber"
                        className='w-full'
                        id="taxNumber"
                        disabled={!navigator.cookieEnabled || this.props.loginByAuthDialog}
                        label={getLabel("TAX_NUMBER")}
                        removeSelectionRule={true}
                        maxLength='9'
                        value={this.props.loginByAuthDialog ? this.props.userName : this.state.login.username}
                        onChange={(e) => this.handleChangeLoginInput({ username: e?.currentTarget?.value })}
                    />

                    <div className="flex gap-2 mt-2 flex-wrap sm:flex-nowrap lg:justify-between">
                        <SimpleButtonComponent
                            disabled={!navigator.cookieEnabled}
                            className="w-full sm:w-1/2 lg:w-24"
                            type='submit'
                        >
                            <span>{getLabel("ENTER")}</span>
                        </SimpleButtonComponent>
                    </div>
                </form>
            </>
        );
    }

    renderLoginForm() {
        return (
            <>
                <form onSubmit={() => this.handleSubmit(0)} className="grid grid-cols-1 justify-between gap-4">
                    <InputText
                        key='username'
                        name="login"
                        className='w-full'
                        id="username"
                        disabled={!navigator.cookieEnabled || this.props.loginByAuthDialog}
                        label={getLabel("USERNAME")}
                        value={this.props.loginByAuthDialog ? this.props.userName : this.state.login.username}
                        onChange={(e) => this.handleChangeLoginInput({ username: e?.currentTarget?.value })}
                    />

                    <InputText
                        key='password'
                        type="password"
                        name="password"
                        className='w-full'
                        id="password" 
                        disabled={!navigator.cookieEnabled}
                        label={getLabel("PASSWORD")}
                        value={this.state.login.password}
                        onChange={(e) => this.handleChangeLoginInput({ password: e?.currentTarget?.value })}
                    />

                    <div className="flex gap-2 mt-2 flex-wrap sm:flex-nowrap lg:justify-between">
                        <SimpleButtonComponent
                            disabled={!navigator.cookieEnabled}
                            className="w-full sm:w-1/2 lg:w-24"
                            type='submit'
                        >
                            <span>{getLabel("ENTER")}</span>
                        </SimpleButtonComponent>
                        
                        <SimpleButtonComponent
                            type="button"
                            variant="custom"
                            onClick={this.submitChaveMovelDigital}
                            disabled={!navigator.cookieEnabled}
                            className={clsx("w-full sm:w-1/2 lg:w-fit text-white bg-chavemoveldigital-blue", {"hidden": this.props.loginByAuthDialog})}
                        >
                            <img alt={getLabel("GOVERNMENT_AUTH")} src={chaveMovelIcon} width="20" />
                            <span>{getLabel("GOVERNMENT_AUTH")}</span>
                        </SimpleButtonComponent>
                    </div>
                </form>
                
                <p className={clsx('flex items-center leading-none mt-6 lg:mt-8', { 'hidden': !navigator.cookieEnabled || this.props?.loginByAuthDialog })}>
                    <SimpleButtonComponent variant="link" onClick={this.setForgotCredentials}>
                        <span className="text-brand-gray700">{getLabel("RECOVERY")}</span>
                    </SimpleButtonComponent>
                    &nbsp;
                    <span>{getLabel("ACCESS_CODE")}</span>
                </p>
                    
                <p className={clsx('flex items-center leading-none', { 'hidden': this.props?.loginByAuthDialog })}>
                    <LinkButton
                        variant="underline"
                        isExternal
                        href={getLink('CHAVE_MOVEL_DIGITAL')}
                    >
                        <span>{getLabel("KNOW_MORE")}</span>
                    </LinkButton>
                    &nbsp;
                    <span>{getLabel("ABOUT_CHAVE")}</span>
                </p>
            </>
        );
    }

    renderResultForgotCredentials = () => {
        return(
            <>
                <div className="h-full content-between pb-4">
                    {this.state.recoverySuccess 
                    ? 
                    <>
                        <h2> {getLabel("SEND_SUCCEED")} </h2>
                        <p> {getLabel("SEND_SUCCEED_TEXT")} </p>
                        <p> {getLabel("THANKS")} </p>
                    </>
                    : 
                    <>
                        <h2> {getLabel("SEND_FAILED")} </h2>
                        <p> {getLabel("SEND_FAILED_TEXT")}</p>
                        <p> {getLabel("THANKS")} </p>
                    </>}
                </div>          

                <div className="flex justify-center">
                    {this.renderGoBackButton()}
                </div>
            </>
        );
    }

    renderGoBackButton = () => {
        return (
            <BackButton
                className='underline'
                hasIcon={false}
                onClick={this.setForgotCredentials}
            />
        );
    }

    renderFormModal = (loginType) => {
        switch(loginType){
            case "1":
                return this.renderLoginFormNIF();
            default:
                if(this.state.isForgotSelected){
                    return (this.state.recoverySuccess || this.state.recoveryFailed) ? this.renderResultForgotCredentials() : this.renderForgotCredentialsForm();
                } else {
                    return this.renderLoginForm();
                }
        }
    }

    getLoginTitle = (loginType) => {
        if(this.props.loginByAuthDialog)
            return getLabel("DIALOG_AUTH_TITLE");
        if(loginType === "1")
            return getLabel("DMIF_ACCESS");

        return getLabel("ACCESS_ACCOUNT");
    }

    renderLoginPage = () => {
        let queryString = new URLSearchParams(window.location.search);
		let loginType = this.props.loginType ?? queryString.get('lt');

        if(this.state.isLoading) {
            return <LoadingComponent isVisible={true} customMessage={getLabel("LOADING")}/>
        }

        return (
            <div className={clsx("max-w-screen-md bg-white w-full py-4 lg:py-7", {'shadow': !this.props.loginByAuthDialog})}>
                {this.renderLoggedOutMessage()}
                
                <div>
                    {this.props.domainType == 'COL' ?
                        <div className="text-center lg:text-left lg:mt-4 lg:pl-8 lg:ml-5 mb-12 text-brand-green600 text-3xl font-titulos">
                            EMPRESA!!!
                        </div> :
                        ''}
                    <div className="text-center lg:text-left lg:mt-4 lg:pl-8 lg:ml-5 mb-12 text-brand-green600 text-3xl font-titulos">
                        {this.getLoginTitle(loginType)}
                    </div>
                    
                    <div className="px-8 lg:mx-5 mt-[-30px] mb-4">
                        {this.renderFinishSessionMessage()}
                    </div>
                    
                    <div className="divide-y divide-gray-200 grid grid-cols-1 lg:grid-cols-2">
                        <div className="p-0 lg:px-5 col-span-1">
                            <div className="px-8 pb-4">
                                {this.renderFormModal(loginType)}
                            </div>
                        </div>
                        
                        <div className="col-span-1 inline-block align-middle content-center lg:!border-t-0 lg:px-5">
                            <div className="mt-4 lg:mt-0 mb-4 lg:border-l-2 px-8">
                                <SecurityInformation />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.isAuthenticated) {
            if (!this.props.handleAuthentication) {
                window.location.reload();
            }
          } else {
            return this.renderLoginPage();
          }
    }
}

export default LoginModal;