import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import Login from '../features/login/login';
import LoginWithChaveMovelDigital from '../features/login/login_chavemoveldigital';
import LoginModal from '../features/login/login_modal';
import { DefaultLayout } from '../layout/DefaultLayout';
import { AuthenticatedPage } from '../pages/AuthenticatedPage';
import { ChangePasswordPage } from '../pages/ChangePasswordPage';
import { ErrorPage } from '../pages/ErrorPage';
import { LogoutPage } from '../pages/LogoutPage';
import NotFoundPage from '../pages/NotFoundPage';
import { RecoveryPage } from '../pages/RecoveryPage';
import { isAuthenticated } from '../utils/authentication/authentication';
import { PATH, URL_RESOURCE } from './constants';
import { LanguageContextProvider } from '../contexts/LanguageContext';
import { getHomePage } from '../utils/menu/menuPages';
import { checkSessionHealth } from '../utils/integration/integration';

const getResetPasswordRoutesConfig = () => ({
  layout: undefined,
  paths: [
    {
      index: true,
      element: <Navigate to={PATH.CHANGE_PASSWORD} replace />,
    },
    {
      path: URL_RESOURCE.LOGIN,
      element: <Navigate to={PATH.CHANGE_PASSWORD} replace />,
    },
    {
      path: URL_RESOURCE.CHANGE_PASSWORD,
      element: <ChangePasswordPage />,
    },
    {
      path: URL_RESOURCE.ALL,
      element: <NotFoundPage />,
    },
  ],
});

const getRoutesConfig = (user) => {
  checkSessionHealth();
  const footerPages = user?.pageList.filter((p) => p.menuLateral !== 1);
  const menuPages = user?.pageList.filter((p) => p.menuLateral === 1);
  const mainPage = getHomePage(menuPages);

  const pagesPaths = menuPages?.map(({ customParams, element = null, path = '', title }) => {
    let pagePath = path ? path.replace(/^\//, '') : '';
    const paramsIndex = pagePath.indexOf('?');

    if (paramsIndex > -1) {
      pagePath = pagePath.substring(0, paramsIndex);
    }

    return {
      path: pagePath,
      errorElement: <ErrorPage />,
      element: (
        <AuthenticatedPage
          pageTitle={title}
          pageCustomParams={customParams}
          pagePath={element}
          accounts={user.userAccounts}
        />
      ),
    };
  });

  return {
    layout: (
      <DefaultLayout
        userAccounts={user.userAccounts}
        pages={{ menuPages, footerPages }}
        userInfo={user.userInfo}
      />
    ),
    paths: [
      {
        index: true,
        element: <Navigate to={mainPage} replace />,
      },
      {
        path: URL_RESOURCE.LOGIN,
        element: <Navigate to={mainPage} replace />,
      },
      {
        path: URL_RESOURCE.LOGIN_CMD,
        element: <Navigate to={mainPage} replace />,
      },
      ...pagesPaths,
      {
        path: URL_RESOURCE.ALL,
        element: <NotFoundPage pages={menuPages} />,
      },
    ],
  };
};

const getAuthenticatedRoutes = ({ onLogout, user }) => {
  const { layout, paths } = user.isResetPassword
    ? getResetPasswordRoutesConfig()
    : getRoutesConfig(user);

  return [
    {
      path: '/',
      element: <Navigate to={PATH.BASE} replace />,
      errorElement: <Navigate to={PATH.BASE} replace />,
    },
    {
      path: PATH.BASE + '/',
      element: layout,
      children: [
        {
          path: URL_RESOURCE.LOGOUT,
          element: <LogoutPage onLogout={onLogout} />,
        },
        ...paths,
      ],
    },
  ];
};

const getNonAuthenticatedRoutes = ({ onAuthenticate }) => {
  return [
    {
      path: '/',
      element: <Navigate to={PATH.BASE} replace />,
      errorElement: <ErrorPage />,
    },
    {
      path: PATH.BASE + '/',
      element: (
        <LanguageContextProvider>
          <Outlet />
        </LanguageContextProvider>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Navigate to={PATH.LOGIN} replace />,
        },
        {
          path: URL_RESOURCE.LOGIN,
          element: <Login handleAuthentication={onAuthenticate} />,
        },
        {
          path: URL_RESOURCE.LOGIN_MODAL,
          element: <LoginModal handleAuthentication={onAuthenticate} />,
        },
        {
          path: URL_RESOURCE.LOGIN_CMD,
          element: <LoginWithChaveMovelDigital handleAuthentication={onAuthenticate} />,
        },
        {
          path: URL_RESOURCE.LOGOUT,
          element: <Navigate to={PATH.LOGIN} replace />,
        },
        {
          path: URL_RESOURCE.RECOVERY,
          element: <RecoveryPage />,
        },
        {
          path: URL_RESOURCE.ALL,
          element: <Login handleAuthentication={onAuthenticate} />,
        },
      ],
    },
  ];
};

export function Router({ onAuthenticate, onLogout, user }) {
  const routes = isAuthenticated()
    ? getAuthenticatedRoutes({ onLogout, user })
    : getNonAuthenticatedRoutes({ onAuthenticate });

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
}
