import resourcesConfig from "../../config/api_resources.json";
import { URL_RESOURCE } from "../../routes/constants";
import { COOKIE } from "../cookies/constants";
import * as cookiesHelper from "../cookies/cookie";
import { caller } from "../integration/integration";
import { LOCAL_STORAGE } from "../storage/constants";
import { localStorageHelper } from "../storage/local_storage";
import { getLabel } from "../translates/login_labels";

export function authenticate({login, password, isChaveMovelDigital = false, userEncoded = null, accessType = 0, metadata}) {
    return new Promise(function (resolve, reject) {
        if (!login && !isChaveMovelDigital) {
            reject(getLabel(accessType === 1 ? 'NIF_REQUIRED_ERROR' : 'LOGIN_REQUIRED_ERROR'));
        } else if (!password && !isChaveMovelDigital) {
            reject(getLabel('PASSWORD_REQUIRED_ERROR'));
        } else {
            caller.postLogin({
                login: login,
                password: password,
                isChaveMovelDigital: isChaveMovelDigital,
                userEncoded: userEncoded,
                accessType: accessType,
                metadata: metadata
            }, isChaveMovelDigital).then(authResult => {
                if (authResult.isError)
                    reject(authResult.errorMessage);
                else {
                    if(authResult.result?.isMultiFactor === 1 || authResult.result?.isStrongAuth === 1 || accessType === 1 || !isChaveMovelDigital){
                        if (window.authDialog !== undefined) 
                            window.authDialog.setLoginData(authResult.result?.mobilePhoneNumber, authResult.result?.userLanguage);
                        
                        openMultiFactorAuthentication(authResult.result, accessType, function(){
                            setAuthCookies(authResult, accessType, login, isChaveMovelDigital);                            
                            resolve({
                                userName: authResult.result.userName,
                                userLanguage: authResult.result.userLanguage,
                                isFirstLogin: authResult.result.isPrimeiroLogin,
                                isPasswordExpired: authResult.result.isPasswordExpired,
                            });
                        });
                    }else{
                        setAuthCookies(authResult, accessType, login, isChaveMovelDigital);                            
                        resolve({
                            userName: authResult.result.userName,
                            userLanguage: authResult.result.userLanguage,
                            isFirstLogin: authResult.result.isPrimeiroLogin,
                            isPasswordExpired: authResult.result.isPasswordExpired,
                        });
                    }
                }
            }).catch((error) => {
                reject(error?.error?.details ?? getLabel('GENERIC_ERROR'));
            });
        }
    });
}

function setAuthCookies(authResult, loginType = 0, login, isChaveMovelDigital = false){
    localStorageHelper.setValue(LOCAL_STORAGE.LOGIN_USERNAME, login);
    localStorageHelper.setValue(LOCAL_STORAGE.LOGIN_TYPE, loginType);
    localStorageHelper.setValue(LOCAL_STORAGE.IS_CMD, isChaveMovelDigital);
    localStorageHelper.setValue(LOCAL_STORAGE.USER_EXPIRATION_TIME, authResult.result.userExpirationTime);
    localStorageHelper.setValue(LOCAL_STORAGE.USER_LANGUAGE, authResult.result.userLanguage);
    localStorageHelper.setValue(LOCAL_STORAGE.USER_LANGUAGE_ID, authResult.result.userLanguageID);
    localStorageHelper.removeValue(LOCAL_STORAGE.EXPIRED_USER_MESSAGE_VIEW);
    cookiesHelper.setCookieWithTime(COOKIE.USER_EXPIRATION_TIME, authResult.result.userExpirationTime, authResult.result.userExpirationTime);
    cookiesHelper.setCookieWithTime(COOKIE.X_LOGINID, authResult.result.LoginID, authResult.result.userExpirationTime);
}

function openMultiFactorAuthentication(user, accessType, successMultiFactorAuth){    
    const urlErrorToRedirect = accessType === 1 ? URL_RESOURCE.LOGIN_PIN_INVALID + URL_RESOURCE.LOGOUT_DMIF : URL_RESOURCE.LOGIN_PIN_INVALID;
    caller.post(resourcesConfig.TRANSACTION.INSERT_TRANSACTION, {
        Type: getLoginTransactionType(user, accessType),
        Parameters: JSON.stringify({
            EntityID: user?.entityId,
            Login: user?.userName
        }),
        Details: null,
        Reference: null
    },false, successMultiFactorAuth, () => cancelMultiFactor(urlErrorToRedirect), true).then(response => {
        if(response.isError){
            document.location.href = urlErrorToRedirect;
        } else {
            successMultiFactorAuth();
        }
    }).catch(error => {
        document.location.href = urlErrorToRedirect;
    }); 
}

function getLoginTransactionType(user, accessType){
    let type = "";
    if(accessType === 1){
        type = "USRSLSMS";
    }else{
        type = user?.isStrongAuth === 1 ? "USRLGSTRAUTH" : "USRLGSMS"
    }
    return type;
}

function cancelMultiFactor(urlErrorToRedirect){
    document.location.href = urlErrorToRedirect;
}

export function logout() {
    return new Promise(function (resolve, reject) {
        caller.post(resourcesConfig.AUTHENTICATION.LOGOUT, null).then(result => {
            resolve(true);
        }).catch(err => {
            resolve(true);
        });
    });
}

export function deleteAuthInfo(){
    cookiesHelper.deleteCookie(COOKIE.USER_EXPIRATION_TIME);
    cookiesHelper.deleteCookie(COOKIE.X_ACCESS_TOKEN);
    cookiesHelper.deleteCookie(COOKIE.X_REFRESH_TOKEN);
    cookiesHelper.deleteCookie(COOKIE.X_USER_LANGUAGE);
    cookiesHelper.deleteCookie(COOKIE.X_LOGINID);
    
    localStorageHelper.clearLocal();
}

export function isAuthenticated() {
    let userExpirationTimeFromCookie = cookiesHelper.getCookie(COOKIE.USER_EXPIRATION_TIME);
    if(!userExpirationTimeFromCookie)
        return false;

    let expirationTime = localStorageHelper.getValue(LOCAL_STORAGE.INACTIVITY_DATE);
    if (expirationTime === null){
        return false;
    }

    let expirationTimeConverted = new Date(expirationTime);
    if (isNaN(expirationTimeConverted))
        return false;

    if (expirationTimeConverted < new Date())
        return false;
    else
        return true;
}

export function isCookiesAllowed() {
    let cookiesAllowed = cookiesHelper.getCookie(COOKIE.COOKIES_ALLOWED);
    if (cookiesAllowed === "1")
        return true;
    else
        return false;
}

export function allowCookies() {
    cookiesHelper.setCookie(COOKIE.COOKIES_ALLOWED, "1", 31);
}